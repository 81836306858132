// Width and height Styles

.event-date-circle {
  width: 45px;
  height: 45px;
  position: relative;
  overflow: hidden;
  padding-top: 5px;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  background-color: #e8eaf6;

  &-month {
    font-size: 12px;
    color: red;
  }

  &-day {
    font-size: 12px;
  }

}

