// Width and height Styles

.loader-label {
  margin-top:10px;
  color: #ccc;
  font-size: 11px;
  animation: blinker 1.5s linear infinite;
}


@keyframes blinker {
  50% {
    opacity: 0;
  }
}