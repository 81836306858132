/*Avatar Styles*/
.user-avatar {
  margin-right: 8px;
  @extend %size-50;

  & img {
    max-width: 100%;
    height: auto;
  }

  &-lg {
    margin-right: 10px;
    @extend %size-60;
  }
}

.user-detail {
  & .user-name {
    margin-bottom: 2px;
    font-weight: 400;
    text-transform: capitalize;
  }

  & .user-description {
    font-size: 13px;
    margin-bottom: 0;
    color: $light-gray;
  }
}


.male-avatar {
  //background-color: #00bfee;
  &.avatar-bg {
    background-color: #00bfee!important;
  }
  &.avatar-border {
    border: 1px solid #00bfee!important;
  }
  &.avatar-icon {
    color: #00bfee!important;
  }
}

.female-avatar {
  //background-color: #eea2ee!important;
  &.avatar-bg {
    background-color: #eea2ee!important;
  }
  &.avatar-border {
    border: 1px solid #eea2ee!important;
  }
  &.avatar-icon {
    color: #eea2ee!important;
  }
}