// Width and height Styles

.quick-menu {
  & .icon-btn {
    color: $white;
  }

  & .icon-alert {
    position: relative;

    &:after {
      display: block;
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      z-index: 10;
      width: 8px;
      height: 8px;
      background-color: $danger;
      @include border-radius(50%);
      @extend %pulse-effect;
    }
  }

  & .header-svg-icon {
    color: $white;
    height:25px;
  }

  & .svg-icon.icon-alert {
    position: relative;

    &:after {
      margin-top:3px;
    }
  }

  & i.animated.infinite {
    -moz-animation-iteration-count: 25;
    -webkit-animation-iteration-count: 25;
    -o-animation-iteration-count: 25;
    animation-iteration-count: 25;
  }
}


.quick-menu {
  & .dropdown-menu {
    padding: 0px 0px;
    width: 350px;

    & .jr-card-header {

      margin: 0 0 0px;
      padding: 4px 13px;
      border-bottom: 1px solid $gray-300;
      z-index: 10;
      background-color: $body-bg;

      & .card-heading {
        margin-top: 3px;
      }
    }

    &:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $white;
      top: -9px;
      content: "";
      height: 9px;
      position: absolute;
      right: 8px;
      width: 10px;
    }

    &:before {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $black;
      top: -10px;
      content: "";
      height: 10px;
      position: absolute;
      right: 8px;
      width: 10px;
    }

    & .jr-card-footer {

      margin: 0 0 0;
      padding: 4px 13px;
      border-top: 1px solid $gray-300;
      z-index: 10;
      background-color: $body-bg;
      font-size: small;

      & a {
        color: $gray-600;
      }

      & a:hover {
        text-decoration: none;
        color: $link-hover-color;
      }

    }
  }
}

.messages-list a:hover {text-decoration: none;}

.messages-list.button-list ul li {
  padding: 0px 0px 0px;

  & .jr-btn {
    align-items: left!important;
    text-align: left;
    padding: 10px 10px 8px!important;
    border-radius:0!important;
    position: unset!important;
    width:100%!important;

    &.unread {
      //background-color: $primary-lighten-50;
      background-color: #fffee8;
    }

    & .arrowBtn {
      width:34px;
      font-size: 20px;
      height:100%;

      & i {
        margin-left:5px;
      }
    }
  }
  & .jr-btn:hover {
    background-color: $body-bg;
  }
}

.messages-list ul li {
  position: relative;
  padding: 10px 10px 10px;
  border-bottom: 1px solid $gray-300;

  & .user-name a {
    color: $app-primary;
  }

  & .sub-heading {
    font-size: 11px;
    margin-bottom: 6px !important;

    &.mb-0 {
      margin-bottom: 0 !important;
    }
  }

  &:last-child {
    border-bottom: 0 none;
  }

  & .jr-btn {
    margin-right: 0 !important;
  }

  & .jr-btn-xs {
    font-size: 11px !important;
    padding: 5px !important;
  }
}