// Width and height Styles

$dialog-padding: 24px;

.dialog-subheader {
  display: flex;
  padding: 10px 10px 10px 15px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.dialog-popup-header {
  padding: 15px 8px!important;
}

.inner {padding: $dialog-padding $dialog-padding;}


.dialog-content-tab {
  padding: 0!important;
  flex: 1 1 auto;

}

.dialog-content-tab.dialog-lg {width:600px;height:700px;}
.dialog-content-tab.dialog-md {width:400px;height:500px;}


.modal-search-bar {

  padding: $dialog-padding $dialog-padding;

  background-color: $body-bg!important;
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  .form-group {
    //border: 0px solid #868e96;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 10px;
    overflow: hidden;

    .search-icon {
      position: absolute;
      left: $dialog-padding + 3!important;
      top: $dialog-padding!important;
    }
    .search-icon:hover {
      cursor: default;
    }

    .reset-icon {
      position: absolute;
      background-color: transparent;
      border: 0 none;
      color: #868e96;
      height: 36px;
      width: 30px;
      right: $dialog-padding + 3!important;
      top: $dialog-padding!important;
    }

    input[type="search"] {
      padding-left: 33px;
    }

  }

}
