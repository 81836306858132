
body {
  /* We need to assaign this CSS Custom property to the body instead of :root, because of CSS Specificity and codepen stylesheet placement before loaded CKE5 content. */
  --ck-z-default: 1000;
  --ck-z-modal: calc( var(--ck-z-default) + 999 );
}

.ck.ck-balloon-panel {
  z-index: 1000;
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne, .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
  left: unset!important;
  right: 0!important;
}

.ck-toolbar {
  border-bottom-left-radius: 0!important;
  border-bottom-right-radius: 0!important;
}

.ck-content {

  border: 1px solid var(--ck-color-toolbar-border)!important;
  border-top: 0 transparent!important;
  border-top-left-radius: 0!important;
  border-top-right-radius: 0!important;
  min-height: 250px;

}