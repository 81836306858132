/* Calendar Module Style */

$today-color: #a37435;
$closed-days-color: #0071bc;
$national-holidays-color: #f7931e;
$school-holidays-color: #662d91;
$event-custom-color: #d6d6d6;
$closed-days-highlight-color: #0071bc9e;
$national-holidays-highlight-color: #f7931e99;
$school-holidays-highlight-color: #662d919e;


@mixin selected-days-button($color) {
  min-width: 132px;
  margin-left: 8px;
  padding: 8px 8px;
  border: 1px solid $color;
  background: white;
  color: $color;
  border-radius: 4px;
  text-align: center;
  transition: .5s all;
  &:hover {
    background: $color;
    color: white;
  }
}

@mixin selected-days-button-selected($color, $highlight-color) {
  min-width: 132px;
  margin-left: 8px;
  padding: 8px 8px;
  border: 1px solid $color;
  background: $color;
  color: white;
  border-radius: 4px;
  text-align: center;
  transition: .5s all;

  &:hover {
    background: $highlight-color;
    color: white;
  }
}

.closed-days {
  @include selected-days-button($closed-days-color);
}

.closed-days-selected {
  @include selected-days-button-selected($closed-days-color, $closed-days-highlight-color);
}

.national-holidays {
  @include selected-days-button($national-holidays-color);
}

.national-holidays-selected {
  @include selected-days-button-selected($national-holidays-color, $national-holidays-highlight-color);
}

.school-holidays {
  @include selected-days-button($school-holidays-color);
}

.school-holidays-selected {
  @include selected-days-button-selected($school-holidays-color, $school-holidays-highlight-color);
}

.DayPicker-Day--closedDays {
  color: #fff !important;
  background-color: $closed-days-color !important;
  &:hover {
    background-color: $closed-days-highlight-color !important;
  }
}

.DayPicker-Day--nationalHolidayDays {
  color: #fff !important;
  background-color: $national-holidays-color !important;
  &:hover {
    background-color: $national-holidays-highlight-color !important;
  }
}

.DayPicker-Day--regularHolidayDays {
  color: #fff !important;
  background-color: $school-holidays-color !important;
  &:hover {
    background-color: $school-holidays-highlight-color !important;
  }
}

.DayPicker-Day--weekends {
  background-color: #f2f2f2;
  color: #000;
}

.DayPicker-Day--outside {
  background-color: transparent;
  color: #8B9898;
  cursor: default;
  pointer-events: none;
  opacity: .3;
}

.DayPicker-Day--today {
  background-color: $today-color !important;
}

.actions {
  position: absolute;
  bottom: 0;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  background: white;
  z-index: 2000;
  width: calc(100vw - 264px);
}

//Override datePicker styles

.DayPicker-Months {
  justify-content: space-around;
}

.DayPicker-Day {
  //border-radius: unset;
  border-radius: 0 !important;
  border: 1px solid #fff;
}

.DayPicker-Caption {
  text-align: center;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: $school-holidays-highlight-color !important;
  color: #F0F8FF;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: $school-holidays-highlight-color;
}

.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: $school-holidays-color;
}

.Range .DayPicker-Day {
  border-radius: 0 !important;
}

// React-Big-Calendar


.event-calender-header {
  margin-bottom: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    font-size: large;
    text-align: center;
    cursor: pointer;
  }
}


.rbc-calendar {
  .app-calendar & {
    @include display-flex(flex, column, nowrap);
    @include flex(1);
    min-height: calc(100vh - 160px);
  }

  .app-cul-calendar & {
    min-height: calc(100vh - 200px);
  }
}

.rbc-event {
  background-color: $app-primary;
  min-height: 15px !important;
}

.event-closed-day {
  background-color: $closed-days-color !important;
  pointer-events: none;
  cursor: default;
}

.event-national-holidays-day {
  background-color: $national-holidays-color !important;
  pointer-events: none;
  cursor: default;
}

.event-regular-holidays-day {
  background-color: $school-holidays-color !important;
  pointer-events: none;
  cursor: default;
}

.event-custom {
  background-color: $event-custom-color;
}

.rbc-event.rbc-selected {
  background-color: darken($app-primary, 10%);
}

.rbc-slot-selection {
  background-color: rgba($app-primary, 0.7);
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: $white;
  background-image: none;
  @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
  //box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: $white;
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-toolbar button:focus {
  color: $white;
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-toolbar button:hover {
  color: $white;
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-month-row {
  min-height: 100px;
}

.rbc-event {
  min-height: 30px;
}

.rbc-today {
  background-color: #ffd500;
}

div.calendar-event-container {

  background-color: #cccccc;
  min-height: 40px;

  &.day-regular {
    background-color: #8f8f8f;
  }




  &.full {
    //background-color: #cc1b00;
  }

  &.finished {

    opacity: 0.3;
  }

}


.calendar-legend {

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-bottom: 10px;
      .legend-box {
        float: left;
        display: inline-block;
        width: 15px; height: 15px;margin-right: 2px;}
      div {
        display: inline-block;
        margin-right: 15px;
      }
    }

  }

  .day-regular {
    background-color: #8f8f8f;
  }

  .regularHoliday {
    background-color: #8f8f8f;
  }

  .closed {
    background-color: #cc1b00;
  }

}

@media screen and (max-width: 867px) {
  .rbc-toolbar {
    @include display-flex(flex, column, nowrap);
    @include align-items(flex-start);
  }

  .rbc-toolbar .rbc-toolbar-label {
    margin: 8px 0;
  }
}

@media screen and (max-height: 600px) {
  .rbc-calendar {
    .app-calendar & {
      min-height: 400px;
    }

    .app-cul-calendar & {
      min-height: 400px;
    }
  }
  .rbc-event {
    &.calendar-event-container {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 467px) {
  .rbc-toolbar {
    font-size: 12px;
  }

  .rbc-toolbar button {
    padding: 5px 10px;
  }
  .rbc-event {
    &.calendar-event-container {
      font-size: 10px;
    }
  }

}


