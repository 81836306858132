// Width and height Styles


.announcements {

  .announcement-description {
    height:20px;
    overflow: hidden;
  }

}