// Width and height Styles

.page-header {
  background-color: #ffffff;
  //min-height:80px;
  border-bottom: 3px solid  #cccccc;
  //padding-top: 25px;

  .page-header-container {
    min-height:80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {margin: 0;}

  }
}
