/*School Manager Page Styles*/

.module-list-item-upgrade {
  padding-right: 90px!important;
}

.module-list-item-unavailable {
  padding-right: 110px!important;
}

.module-list-item-installed {
  padding-right: 110px!important;
}

