// Width and height Styles


.messenger {

  .search-wrapper {
    .search-icon{
       top:0px;
    }
    .reset-icon {
      position: absolute;
      top:5px;
      right:3px;
      background-color: transparent;
      border: none;
      color: #868e96;
      cursor: pointer;
    }
  }

  .aside-messages-list {
    li {
      border-bottom: solid 1px #e9ecef;


      button {
        padding: 10px 10px 10px 10px!important;

        color: inherit;
        border: 0;
        margin: 0;
        cursor: pointer;
        display: inline-flex;
        outline: none;

        position: relative;
        align-items: center;
        user-select: none;
        border-radius: 0;
        vertical-align: middle;
        justify-content: center;
        -moz-appearance: none;
        text-decoration: none;
        background-color: transparent;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;


        .heading {font-weight: inherit;}
        .sub-heading {font-size: 11px;font-weight: inherit;}

        .conversation-date {
          font-size: 13px;
          color: #ccc;
          font-weight: inherit;
        }

      }


      a {
        padding: 10px 5px 10px 10px !important;

        color: inherit;
        border: 0;
        margin: 0;
        cursor: pointer;
        display: inline-flex;
        outline: none;

        position: relative;
        align-items: center;
        user-select: none;
        border-radius: 0;
        vertical-align: middle;
        justify-content: center;
        -moz-appearance: none;
        text-decoration: none;
        background-color: transparent;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;


        .heading {
        }

        .sub-heading {
          font-size: 11px;
        }
      }



    }

    li.unread {
      //background-color: #f1f4f5;

      .heading {font-weight: bold;}
      .sub-heading {font-weight: bold;max-width:250px;}
      .conversation-date {font-weight: bold;}
    }

    li.noParent {
      //background-color: #f57678;
      .noParentText {
        font-weight:bold;
        color: #f50007;
      }
    }

    li.active, li.selected {
      background-color: #fffee8;
    }

    li.active:hover, li.selected:hover{
      background-color: #fffee8;
    }

    li:hover {
      background-color: #f1f4f5;
    }


  }

}